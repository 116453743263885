import Constants from '../Constants';

function isValidEmail(mail) {
    if (mail.length > Constants.MAX_MAIL_LENGTH ||
        mail.length < Constants.MIN_MAIL_LENGTH)
            return false;

    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(mail);
}

function isValidPassword(pass) {
    if (pass.length > Constants.MAX_PASSWORD_LENGTH ||
        pass.length < Constants.MIN_PASSWORD_LENGTH)
            return false;

    const isSymbol = function(value) {
        const upper = value.toLocaleUpperCase();
        const lower = value.toLocaleLowerCase();

        return upper === lower;
    };

    const isLower = function(value) {
        return value === value.toLocaleLowerCase();
    }

    const isCap = function(value) {
        return value === value.toLocaleUpperCase();
    }

    const split = pass.split('');

    let caps = 0;
    let lowers = 0;
    let nums = 0;
    let symbols = 0;

    split.forEach(function(x) {
        if (!isNaN(Number(x))) {
            nums += 1;

        } else if (isSymbol(x)) {
            symbols += 1;

        } else if (isLower(x)) {
            lowers += 1;

        } else if (isCap(x)) {
            caps += 1;

        }
    });

    return caps > 0 && lowers > 0 && nums > 0 && symbols > 0;
}

function equalObjects(obj1, ...objs) {
    const keys = Object.keys(obj1);
    let equals = true;
    let i = 0;

    while (i < objs.length && equals) {
        let k = 0;
        while(k < keys.length && equals) {
            const orig = obj1[keys[k]];
            const dest = objs[i][keys[k]];
            if (orig instanceof Array && dest instanceof Array) {
                if (orig.length != dest.length)
                    equals = false;
                else
                    equals = equalObjects(orig, dest);

            } else if (orig instanceof Object && dest instanceof Object) {
                equals = equalObjects(orig, dest);

            } else if (obj1[keys[k]] != objs[i][keys[k]]) {
                equals = false;
            }

            k += 1;
        }

        i += 1;
    }

    return equals;
}

function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}

function crop(min, max, value) {
    if (value > max)
        return max;

    if (value < min)
        return min;

    return value;
}

function emptyObject(obj) {
    Object.keys(obj).forEach(k => {
        if (obj[k] instanceof Array) {
            obj[k].length = 0;
        } else {
            delete obj[k];
        }
    });
}

function getImageMime(buffer) {
    const signatures = {
        png: new Uint8Array([0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A]),
        jpg: new Uint8Array([0xFF, 0xD8]),
        gif1: new Uint8Array([0x47, 0x49, 0x46, 0x38, 0x37, 0x61]),
        gif2: new Uint8Array([0x47, 0x49, 0x46, 0x38, 0x39, 0x61])
    };

    try {
        if (signatures.png.every((i, idx) => buffer[idx] === i))
            return 'image/png';

        if (signatures.jpg.every((i, idx) => buffer[idx] === i))
            return 'image/jpg';

        if (signatures.gif1.every((i, idx) => buffer[idx] === i))
            return 'image/gif';

        if (signatures.gif2.every((i, idx) => buffer[idx] === i))
            return 'image/gif';

        throw 0;
    } catch {
        return 'application/octet-stream';
    }
}

function thresholdLabeler(value, arr) {
    for (let i = 0; i < arr.length; i++) {
        if (value < arr[i][0]) {
            const ix = i - 1;
            return arr[ix < 0 ? 0 : ix][1];
        }
    }

    return arr[arr.length - 1][1];
}

function isInt(val) {
    return typeof val === 'number' && Math.trunc(val) == val;
}

export {
    thresholdLabeler,
    isInt,
    isValidEmail,
    isValidPassword,
    equalObjects,
    hslToHex,
    crop,
    emptyObject,
    getImageMime };