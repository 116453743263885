<template>
    <app-sidebar />
    <app-nav />
    <app-content />
    <app-footer />
</template>

<script>
import API from "../utils/api-utils.js";

import AppSidebar from '../views/app-sidebar.vue';
import AppNav from "../views/app-nav.vue";
import AppContent from "../views/app-content.vue";
import AppFooter from "../views/app-footer.vue";

import GLOBAL_ACTIVITIES from "../db/db-activities.json";
import GLOBAL_COUNTRIES from "../db/db-countries.json";
import GLOBAL_LEGALSIZES from "../db/db-legalsizes.json";
import GLOBAL_SPECIES from "../db/db-species.json";

export default {
    name: "Base",
    components: {
        AppSidebar,
        AppNav,
        AppContent,
        AppFooter,
    },

    provide() {
        return {
            GLOBAL_ACTIVITIES,
            GLOBAL_COUNTRIES,
            GLOBAL_LEGALSIZES,
            GLOBAL_SPECIES,
            COMPANY: this.company
        };
    },

    data() {
        return {
            company: {
                value: {}
            }
        }
    },

    created() {
        const company = this.company.value;
        API.read.company()
            .then(json => {
                if (json.ok) {
                    company.companyName = json.data.companyName;
                    company.numWorkers = json.data.size;
                    company.nif = json.data.vat;
                    company.address = json.data.address.street;
                    company.countryDescription = json.data.address.country.description;
                    company.zipCode = json.data.address.zipCode;
                    company.city = json.data.address.city;
                }
            });
    }
};
</script>
