import './_custom.scss';
import '@fortawesome/fontawesome-free/js/all.min.js';

import Constants from './Constants.js';

import { createApp } from 'vue';
import Vue3MarkdownIt from 'vue3-markdown-it';
import Router from './router.js';
import App from './App.vue';

const app = createApp(App);

if (Constants.IS_DEVELOPMENT && window.__VUE_DEVTOOLS_GLOBAL_HOOK__) {
    window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;
}

app.use(Vue3MarkdownIt);
app.use(Router);
app.mount('#app');