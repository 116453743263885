import { createRouter, createWebHistory } from 'vue-router';

import CompanyRoutes from './pages/company/router.company';
import DashboardRoutes from './pages/dashboard/router.dashboard';
import DataentryRoutes from './pages/data-entry/router.data-entry';

const routes = [
    { path: '/', component: () => import('./pages/REDIRECT_HOME.vue') },
    
    CompanyRoutes,
    DashboardRoutes,
    DataentryRoutes
];

export default createRouter({
    history: createWebHistory(),
    routes
});