<template>
    <div
        :class="`d-flex alert alert-${level}`"
        role="alert"
    >
        <span class="text">
            {{ text }}
        </span>
        <span
            class="icon"
            @click="() => $emit('dispose')"
        >
            <i class="fas fa-times" />
        </span>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },

        level: {
            type: String,
            required: true
        }
    },

    emits: ['dispose']
}
</script>

<style scoped>
.text {
    flex-grow: 1;
}

.icon:hover {
    cursor: pointer;
    filter: grayscale()
}
</style>