<template>
    <vue3-markdown-it
        :html="true"
        :source="source"
        :plugins="plugins"
    />
</template>

<script>
import MarkdownItKatex from 'markdown-it-katex';

import __ from '../utils/translator.js';

export default {
    components: {},

    props: {
        pageId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            source: '',

            plugins: [
                {
                    plugin: MarkdownItKatex,
                    options: {
                        "throwOnError" : true, "errorColor" : " #cc0000"
                    }
                }
            ]
        }
    },

    watch: {
        pageId(newVal) {
            this.updateSource(newVal)
        }
    },

    methods: {
        async updateSource(pageId) {
            const content = await import(`./pages/${pageId}`)
                .then(mod => {
                    return mod.default;
                })
                .catch(() => {
                    return __('WIKI.NOT_FOUND');
                });

            this.source = content;
        },

        __ //translator
    }
}
</script>