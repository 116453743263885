export default {
    path: '/dashboard',
    component: () => import('./dashboard.vue'),
    children: [
        //Default route is zootech
        {
            path: '',
            component: () => import('./dashboard.summary.vue')
        },
        {
            path: 'summary',
            component: () => import('./dashboard.summary.vue')
        },
        {
            path: 'zootech',
            component: () => import('./dashboard.zootech.vue')
        },
        {
            path: 'health',
            component: () => import('./dashboard.health.vue')
        },
        {
            path: 'welfare',
            component: () => import('./dashboard.welfare.vue')
        },
        {
            path: 'environment',
            component: () => import('./dashboard.environment.vue')
        },
        {
            path: 'economics',
            component: () => import('./dashboard.economics.vue')
        },
        {
            path: 'governance',
            component: () => import('./dashboard.governance.vue')
        }
    ]
};