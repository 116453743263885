const Constants = {
    MAX_PASSWORD_LENGTH: 70,
    MIN_PASSWORD_LENGTH: 8,

    MAX_MAIL_LENGTH: 320,
    MIN_MAIL_LENGTH: 5,

    MAX_COMPANYNAME_LENGTH: 50,
    MIN_COMPANYNAME_LENGTH: 2,

    MAX_NIF_LENGTH: 15,
    MIN_NIF_LENGTH: 6,

    MAX_ADDRESS_LENGTH: 100,
    MIN_ADDRESS_LENGTH: 2,

    MAX_ZIP_LENGTH: 10,
    MIN_ZIP_LENGTH: 2,

    MAX_REGION_LENGTH: 50,
    MIN_REGION_LENGTH: 2,

    MAX_FACILITYNAME_LENGTH: 30,
    MIN_FACILITYNAME_LENGTH: 2,

    MAX_ACTIVITYARRAY_LENGTH: 9,

    MAX_COUNTRYID_VALUE: 244,
    MIN_COUNTRYID_VALUE: 0,

    MAX_LEGALSIZEID_VALUE: 3,
    MIN_LEGALSIZEID_VALUE: 0,

    MIN_CATEGORYID_VALUE: 0,

    MAX_ACTIVITYID_VALUE: 2,
    MIN_ACTIVITYID_VALUE: 0,

    MAX_SPECIEID_VALUE: 2,
    MIN_SPECIEID_VALUE: 0,

    MAX_KPIID_VALUE: 6,
    MIN_KPIID_VALUE: 0,

    MAX_FACILITIES: 500,

    MIN_KPIDATE_VALUE: 2018,
    MAX_KPIDATE_VALUE: new Date().getUTCFullYear(),

    KPI_ZOOTECH_ID: 0,
    KPI_HEALTH_ID: 1,
    KPI_WELFARE_ID: 2,
    KPI_ENVIRONMENT_ID: 3,
    KPI_ECONOMICS_ID: 4,
    KPI_GOVERNANCE_ID: 5,

    TOKEN_RENEWAL_MILLIS: 240000,

    IS_DEVELOPMENT: process.env.VUE_APP_PRODUCTION == 0,

    CORE_API_BASE_URL: process.env.VUE_APP_CORE_API_BASE_URL,
    CLOUD_API_BASE_URL: process.env.VUE_APP_CLOUD_API_BASE_URL,
    CLOUD_URL: process.env.VUE_APP_CLOUD_URL,
    CURRENT_URL: process.env.VUE_APP_CURRENT_URL
};

export default Constants;