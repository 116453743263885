<template>
    <div
        id="background"
        :style="`background-image: url(${ styles.backgroundImage })`"
    >
        <!-- Subscription warning -->
        <overlayed-card v-if="buffer.showSusbcriptionOverlay">
            <template #content>
                <p class="h3 mb-4">
                    {{ __('LOGIN.NO_SUBSCRIPTION.TITLE') }}
                </p>
                <p class="h6 my-4">
                    {{ __('LOGIN.NO_SUBSCRIPTION.PURCHASE_BENCHMARKING') }}
                </p>
                <div class="d-flex">
                    <button
                        class="btn btn-secondary"
                        @click="cancelSubscriptionOverlay"
                    >
                        {{ __('BUTTONS.CANCEL') }}
                    </button>
                    <button
                        class="btn btn-primary ml-3"
                        @click="gotoMyAccount"
                    >
                        {{ __('BUTTONS.GOTO_MY_ACCOUNT') }}
                    </button>
                </div>
            </template>
        </overlayed-card>

        <!-- Login screen -->
        <div class="card center">
            <div class="cloud-logo">
                <img src="@/assets/cloud-logo.png">
            </div>
            <img src="@/assets/login-logo.png">
            
            <loading-layer :is-loading="buffer.isLoading" />

            <div class="card-body">    
                <p class="h6 font-weight-bold">
                    Login
                </p>

                <form @submit="checkForm">
                    <div class="form-group row px-3">
                        <label
                            for="mail"
                            class="col-form-label"
                        >Email</label>
                        <input
                            id="mail"
                            v-model="formData.mail"
                            type="text"
                            class="form-control"
                            placeholder="Enter mail"
                        >

                        <label
                            for="password"
                            class="col-form-label"
                        >Password</label>
                        <input
                            id="password"
                            v-model="formData.pass"
                            type="password"
                            class="form-control"
                            placeholder="Enter password"
                            autocomplete="current-password"
                        >
                        <p class="my-2">
                            {{ __('LOGIN.CANT_LOGIN') }}
                            <a
                                v-once
                                :href="buffer.forgotPasswordUrl"
                                target="_blank"
                            >
                                {{ __('LOGIN.LOST_PASSWORD') }}
                            </a>
                        </p>
                        <p class="my-4">
                            {{ __('LOGIN.NEW_USER') }}
                            <a
                                v-once
                                :href="buffer.signUpUrl"
                                target="_blank"
                            >
                                {{ __('LOGIN.NEW_USER_LINK') }}
                            </a>
                        </p>
                        <input
                            class="btn-wide btn-primary w-100 mt-3"
                            type="submit"
                            value="Login"
                        >
                    </div>
                    <div
                        v-if="buffer.formError.generalComment != ''"
                        class="alert alert-danger"
                        role="alert"
                    >
                        {{ buffer.formError.generalComment }}
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import jwtDecode from 'jwt-decode';
import { SubscriptionGroups, SubscriptionManager, SubscriptionTypes } from '../utils/subscription-util.js';
import Constants from '../Constants.js'
import API from '../utils/api-utils.js'
import __ from '../utils/translator.js';
import { isValidPassword, isValidEmail } from '../utils/form-utils.js';

import LoadingLayer from '../components/loading_layer.vue';
import OverlayedCard from '../components/overlayed-card.vue';

export default {
    components: {
        LoadingLayer,
        OverlayedCard
    },

    emits: ['logged', 'notify'],

    data() {
        return {
            buffer: {
                forgotPasswordUrl: `${Constants.CLOUD_URL}/auth/reset`,
                signUpUrl: `${Constants.CLOUD_URL}/auth/signup?platform=benchmarking`,

                accessToken: '',
                refreshToken: '',

                isLoading: false,

                showSusbcriptionOverlay: false,

                formError: { //Handling the form errors in frontend
                    user: false,
                    pass: false,

                    generalComment: ''
                }
            },
            styles: {
                backgroundImage: require('@/assets/login-background.png')
            },
            formData: {
                mail: '',
                pass: ''
            }
        };
    },

    mounted() {
        this.$router.isReady().then(() => {
            const query = this.$router.currentRoute.value.query;
            const logoutReason = query.logoutReason;
            if (logoutReason) {
                this.$emit('notify', {
                    level: 'info',
                    text: logoutReason
                });
            }
        });
    },

    beforeMount() {
        if (location.pathname != '/')
            location.pathname = '/';
    },

    methods: {
        checkForm(ev) {
            ev.preventDefault();

            //Reset the errors
            this.buffer.formError.user = '';
            this.buffer.formError.pass = '';
            this.buffer.formError.generalComment = '';

            const email = this.formData.mail;
            const password = this.formData.pass;

            if (!isValidPassword(this.formData.pass) || !isValidEmail(this.formData.mail)){
                this.buffer.formError.general = true;
                this.buffer.formError.generalComment = 'Invalid credentials';
                return;
            }

            this.buffer.isLoading = true;
            API.auth.login({ email, password })
                .then((response) => {
                    if (response.ok) {
                        this.checkToken(response.data);

                    } else {
                        this.buffer.formError.generalComment = 'Invalid credentials';

                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.buffer.formError.generalComment = 'Network error, try again later';
                })
                .finally(() => this.buffer.isLoading = false);
        },

        checkToken({ token, refreshToken }) {
            const decode = jwtDecode(token);

            const sbs_t = JSON.parse(decode.sbs_t);
            SubscriptionManager.fromToken(sbs_t);

            if (!SubscriptionManager.checkSubscription([
                    [SubscriptionGroups.BENCHMARKING, SubscriptionTypes.BASIC],
                    [SubscriptionGroups.BENCHMARKING, SubscriptionTypes.STANDARD],
                    [SubscriptionGroups.BENCHMARKING, SubscriptionTypes.PREMIUM]
                ])) {

                    //NO SUBSCRIPTION
                    this.buffer.accessToken = token;
                    this.buffer.refreshToken = refreshToken;
                    this.buffer.showSusbcriptionOverlay = true;

                    return;
            }

            API.token = token; //save token
            API.refreshToken = refreshToken;
            this.$emit('logged');
        },

        cancelSubscriptionOverlay(ev) {
            ev.preventDefault();

            this.buffer.showSusbcriptionOverlay = false;

            this.buffer.accessToken = '';
            this.buffer.refreshToken = '';
            this.formData.mail = '';
            this.formData.pass = '';
        },

        gotoMyAccount(ev) {
            const url = `${Constants.CLOUD_URL}/settings/4?accessToken=${this.buffer.accessToken}&refreshToken=${this.buffer.refreshToken}`;
            window.open(url, '_blank');

            this.cancelSubscriptionOverlay(ev);
        },

        __
    }
}
</script>

<style lang="scss" scoped>
.cloud-logo {
    background-color: #2a3042;
    padding: 2.5rem;

    img {
        width: 100%;
    }
}

#background {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: 100%;
}

.center {
    max-width: 400px;
    min-width: 250px;
}
</style>