export default {
    path: '/data-entry',
    component: () => import('./data-entry.vue'),
    children: [
        //Default route is zootech
        {
            path: '',
            component: () => import('./data-entry.zootech.vue')
        },
        {
            path: 'zootech',
            component: () => import('./data-entry.zootech.vue')
        },
        {
            path: 'health',
            component: () => import('./data-entry.health.vue')
        },
        {
            path: 'welfare',
            component: () => import('./data-entry.welfare.vue')
        },
        {
            path: 'environment',
            component: () => import('./data-entry.environment.vue')
        },
        {
            path: 'economics',
            component: () => import('./data-entry.economics.vue')
        },
        {
            path: 'governance',
            component: () => import('./data-entry.governance.vue')
        },
        // {
        //     path: 'product',
        //     component: () => import('./data-entry.product.vue')
        // }
    ]
};