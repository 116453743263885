<template>
    <div
        id="body"
        class="mx-5 mb-5"
    >
        <router-view />
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import '../_custom.scss';

@include media(xs) {
    #body {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
}
</style>