export default {
    path: '/company',
    component: () => import('./company.vue'),
    children: [
        //Default route is general-data
        {
            path: '',
            component: () => import('./company.general-data.vue')
        },
        {
            path: 'general-data',
            component: () => import('./company.general-data.vue')
        },
        {
            path: 'facilities',
            component: () => import('./company.facilities.vue'),
        }
    ]
};