<template>
    <app-notifications>
        <template #notifications>
            <top-notification
                v-for="(notif, i) in notifications"
                :key="i"
                :level="notif.level"
                :text="notif.text"
                @dispose="disposeNotification(i)"
            />
        </template>
    </app-notifications>
    <app-login
        v-if="buffer.login"
        @logged="logged"
        @notify="(notif) => pushNotification(notif)"
    />
    <index v-if="!buffer.login" />
</template>

<script>
import API from "./utils/api-utils.js";
import Constants from './Constants.js';

import TopNotification from './components/top-notification.vue';

import Index from "./pages/Index.vue";
import AppNotifications from "./views/app-notifications.vue";
import AppLogin from "./views/app-login.vue";


// If is in development, log the API
if (Constants.IS_DEVELOPMENT)
    console.log("API: ", API);

export default {
    name: "App",
    components: {
        TopNotification,
        AppNotifications,
        AppLogin,
        Index
    },

    data() {
        return {
            notifications: [],

            buffer: {
                login: API.token === null,
            },
        };
    },

    //When token is passed as query param
    mounted() {
        this.$router.isReady().then(() => {
            const token = this.$router.currentRoute.value.query.token;
            const refreshToken = this.$router.currentRoute.value.query.refreshToken;
            if (token !== undefined) {
                API.token = token;
                API.refreshToken = refreshToken;
                this.logged();
            }
        })
    },

    methods: {
        logged() {
            this.buffer.login = API.token === null;
        },

        disposeNotification(index) {
            const a = this.notifications.splice(index);
            this.notifications = this.notifications.concat(a.splice(1));
        },

        pushNotification(notif) {
            this.notifications.push(notif);
        }
    }
};
</script>
