<template>
    <div>
        <div>
            <div class="d-flex flex-wrap bg-dark py-1">
                <div
                    id="main-logo"
                    class="ml-3"
                >
                    <a
                        class="nav-link"
                        href="#"
                        @click="(ev) => goto(ev, '/dashboard')"
                    >
                        <img
                            class="logo"
                            src="@/assets/benchmarking-logo.jpeg"
                        >
                    </a>
                </div>

                <companies-list
                    v-if="companyList.length != 0"
                    :company-list="companyList"
                />

                <user-admin-menu />
            </div>
        </div>

        <main-menu-top-bar 
            :menu-items="menuItems"
        />
    </div>
</template>

<script>
import UserAdminMenu from '../components/user-admin-menu.vue';
import CompaniesList from '../components/companies_list.vue';
import MainMenuTopBar from '../components/main-menu-top-bar.vue';

import __ from '../utils/translator.js';

import API from '../utils/api-utils.js';

export default {
    name: 'AppNav',
    components: {
        UserAdminMenu,
        CompaniesList,
        MainMenuTopBar
    },

    data() {
        return {
            enabled_dashboard: false,
            enabled_company: false,
            enabled_data_entry: false,

            companyList: []
        };
    },

    computed: {
        menuItems() {
            return [
                {
                    title: __('TOPBAR.DASHBOARD'),
                    onclick: (ev) => this.goto(ev, '/dashboard'),
                    iconClass: 'fas fa-tachometer-alt',
                    active: this.enabled_dashboard
                },
                {
                    title: __('TOPBAR.COMPANY'),
                    onclick: (ev) => this.goto(ev, '/company'),
                    iconClass: 'fas fa-suitcase',
                    active: this.enabled_company
                },
                {
                    title: __('TOPBAR.DATA_ENTRY'),
                    onclick: (ev) => this.goto(ev, '/data-entry'),
                    iconClass: 'fas fa-edit',
                    active: this.enabled_data_entry
                }
            ];
        }
    },

    mounted() {
        this.setEnabledMenuItem();
    },

    created() {
        API.admin.companyList()
            .then(json => {
                if (!json.ok)
                    return;

                this.companyList = json.data;
            })
            .catch(() => {});
    },

    methods: {
        goto(ev, path) { //Programmatic navigation
            ev.preventDefault();
            this.$router.push({ path });
            this.setEnabledMenuItem(path);
        },

        setEnabledMenuItem(path) {
            this.enabled_dashboard = false;
            this.enabled_company = false;
            this.enabled_data_entry = false;

            //Set "current" class in the current menu section
            /* SET CURRENT MENU CLASS */
            switch (path || this.$route.path) {
                case '/':
                case '/dashboard':

                    this.enabled_dashboard = true;
                    break;
                case '/company':
                    this.enabled_company = true;
                    break;
                case '/data-entry':
                    this.enabled_data_entry = true;
                    break;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../_custom.scss';

@include media(xs) {
    #main-logo {
        margin-left: auto !important;
        margin-right: auto !important;
    }
};

img.logo {
    height: 35px;
    border-radius: 6px;
}
</style>
