import EN from '../lang/en.json';


//TODO: Finish translator

export default function __(index) {
    const splt = index.split('.');

    let obj = EN;
    let i = 0;
    while (obj[splt[i]] != undefined) {
        obj = obj[splt[i++]];
    }

    return i == splt.length ? obj : index;
}