<template>
    <div class="bg-dark">
        <div class="border-left form-group col-md pl-4">
            <select
                v-model="selectedCompany"
                class="form-control"
            >
                <option
                    v-for="c in companyList.list"
                    :key="c.tenantId"
                    :value="c.tenantId"
                >
                    {{ c.companyName }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
import API from '../utils/api-utils.js';

export default {
    name: 'CompanySelector',

    props: {
        companyList: {
            required: true,
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            selectedCompany: this.companyList.current
        }
    },

    watch: {
        selectedCompany(newVal) {
            this.switchUser(newVal);
        }
    },

    methods: {
        switchUser(tenantId) {
            API.admin.otherAuth(tenantId)
                .then(response => {
                    if (response.ok) {
                        API.token = response.data.token;
                        API.refreshToken = response.data.refreshToken;
                        location.reload();
                    }
                });
        }
    }
}
</script>