<template>
    <div
        id="sidebar"
        ref="sidebar"
        class=""
    >
        <div class="top">
            <a
                href="#"
                @click="close"
            >
                <i class="fa fa-times-circle" />
            </a>
        </div>

        <div class="mid">
            <p class="h3">
                {{ __('WIKI.TITLE') }}
            </p>
            <hr>
            <div
                ref="wikiContent"
                class="wiki-content"
            >
                <wiki :page-id="wikiPageId" />
            </div>
        </div>
    </div>
</template>

<script>
import Wiki from '../wiki/wiki.vue';
import __ from '../utils/translator.js';

export default {
    components: {
        Wiki
    },

    data() {
        return {
            wikiPageId: ''
        }
    },

    watch: {
        $route(to) {
            if (!to.query.wiki) {
                this.$refs.sidebar.className = '';
                return;
            }

            this.$refs.sidebar.className = 'open';
            this.wikiPageId = to.query.wiki;
        }
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.resetSidebarHeight();
        });
        this.resetSidebarHeight();
    },

    methods: {
        resetSidebarHeight() {
            this.$refs.wikiContent.style.height = `${window.innerHeight - 150}px`;
        },

        close(ev) {
            ev.preventDefault();
            this.$router.push({ query: { wiki: undefined } });
        },

        __ //translator
    }
}
</script>

<style lang="scss" scoped>
@import '../_custom.scss';

#sidebar {
    position: fixed;
    height: 100vh;
    width: 0vw;
    top: 0;
    right: 0;
    background-color: white;
    z-index: 10;
    box-shadow: 0px 0px 45px -30px rgba(0,0,0,0.75);
    transition: width .2s ease;
    width: 0px;

    &.open {
        width: 500px;

        @include media(xs) {
            width: 100%;
        }
    }

    .top {
        @extend .ml-auto, .mr-2, .my-1;
        width: fit-content;

        a {
            @extend .text-dark;
            font-size: 2em;
        }
    }

    .mid {
        @extend .mx-4;
        .wiki-content {
            overflow-y: auto;
        }
    }
}
</style>