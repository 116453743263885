<template>
    <div
        v-if="isLoading"
        id="layer"
    >
        <img
            id="image"
            src="@/assets/loading.gif"
        >
    </div>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            default: () => false,
            required: true
        }
    }
}
</script>

<style scoped>
#layer {
    pointer-events: all;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 99;
}

#image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
}
</style>