import Constants from '../Constants';

const API_ERROR_CODES = {
    BAD_REQUEST: 100,
    POLL_ERROR: 101,
    DATABASE_ERROR: 102,
    NOT_IMPLEMENTED: 103,
    FORBIDDEN: 104,
    BAD_TOKEN: 105,
    CRITICAL: 106,
    NOT_FOUND: 107,
    CREATE_ERROR: 108,
    UPDATE_ERROR: 109,
    DELETE_ERROR: 110,
    
    //#region suite
    SUBSCRIPTION_NOT_ALLOWED: 200,
    PERMISSION_NOT_ALLOWED: 201,
    //#endregion

    FILTER_GROUPING_ERROR: 1004,
    FILTER_PARAM_ERROR: 1005,

    //#region  medusa
    DEVICE_ID_NOT_VALID: 1000,
    DEVICE_ID_NOT_FOUND_OR_ALREADY_ASSIGNED: 1002,
    DEVICE_ID_NOT_FOUND_OR_NOT_ASSIGNED: 1003,

    TIME_ALREADY_REGISTERED: 1006,

    INVALID_MESSAGE_DOCUMENT: 1007,
    INVALID_WARNING_DOCUMENT: 1008,
    //#endregion

    //#region benchmarking
    BAD_KPI_TYPE: 1009,
    USER_WITHOUT_FACILITIES: 1010,
    KPI_VALIDATION_ERROR: 1011
    //#endregion
};

class Response {
    constructor(ok, data, error) {
        this.ok = ok || false;
        this.data = data;
        this.error = error;
    }
}

function toQueryString(obj) {
    const query = [];

    Object.entries(obj).forEach( ([key, value]) => {
        if (Array.isArray(value)) {
            const valuesArray = value.map(v => v === null ? 'null' : v); //activityId may be null

            query.push(`${key}=${valuesArray.join(',')}`);
        } else {
            query.push(`${key}=${value}`);
        }
    });

    return '?' + query.join('&');
}

const retryPipeline = new function() {
    this.enqueuedRetry = null;

    this.pipe = async (...args) => {
        
        if (this.enqueuedRetry == null) {
            this.enqueuedRetry = API.auth.refreshToken(API.token, API.refreshToken)
                .then(resp => {
                    if (resp.ok) {
                        API.token = resp.data.token;
                        API.refreshToken = resp.data.refreshToken;
                    } else {
                        API.logOut();
                    }
                })
                .catch(() => {
                    API.logOut()
                });

            await this.enqueuedRetry;
        } else {
            await this.enqueuedRetry;
        }

        this.enqueuedRetry = null;

        const obj = args[2];
        obj.retry = false;

        return await defaultFetch(args[0], args[1], obj);
    }
};

// console.log(retryPipeline);

const cloudPipeline = new function() {

    this._attachThen = async (thenableFn, retry, ...args) => {
        return await thenableFn(...args).then(json => {
                return new Response(true, json.data || json, undefined);
            })
            .catch(async (err) => {
                if (err.status == 401 && retry) {
                    return await this._attachThen(retryPipeline.pipe, false, ...args);
                }

                if (err.status == 404) {
                    return new Response(false, undefined, {
                        code: API_ERROR_CODES.NOT_FOUND,
                        message: 'resource not found'
                    });
                }
            });
    }

    this.pipe = async (...args) => {
        return await this._attachThen(defaultFetch, true, ...args);
    }
};

async function defaultFetch(
    url,
    method,
    { useToken = true,
        body = undefined,
        parseRequest = true,
        responsify = true,
        type = 'application/json',
        retry = true
    }) {

    if (retryPipeline.enqueuedRetry != null) {
        await retryPipeline.enqueuedRetry;
    }

    const headers = {};
    
    if (useToken) {
        headers['Authorization'] = `Bearer ${API.token}`;
    }

    if (body) {
        headers['Content-Type'] = type;
    }

    const init = {
        method,
        headers,
        body: parseRequest ? JSON.stringify(body) : body
    };

    return await fetch(url, init)
        .then((response) => {
            if (response.status != 200)
                throw response;

            return response.json();
        })
        .then(async json => {
            if (responsify) {
                let response = new Response(json.ok, json.data, json.error);

                if (response.ok == true || response.error.code != API_ERROR_CODES.BAD_TOKEN || !retry || !useToken) {
                    return new Response(json.ok, json.data, json.error);
                }

                return await retryPipeline.pipe(url, method, { useToken, body, parseRequest, responsify, type, retry });
            }

            return await json;
        });
}

// ---------- API ACTIONS ----------
async function login(data) {
    const body = { 
        forApp: Constants.SELF_APPLICATION_ID,
        ...data
    };

    return cloudPipeline.pipe(Constants.CLOUD_API_BASE_URL + '/auth/login', 'POST', { useToken: false, body, responsify: false });
}

async function companyRead() {
    return cloudPipeline.pipe(Constants.CLOUD_API_BASE_URL + '/company', 'GET', { responsify: false });
}

async function facilityCreate(form) {
    const body = { ...form };

    return defaultFetch(Constants.CORE_API_BASE_URL + '/facility', 'POST', { body });
}
async function facilityRead(enabled=true) {
    return defaultFetch(Constants.CORE_API_BASE_URL + `/facility?enabled=${enabled}`, 'GET', { });
}
async function facilityUpdate(facilityName, form) {
    const body = { ...form };

    return defaultFetch(Constants.CORE_API_BASE_URL + '/facility/' + facilityName, 'PUT', { body });
}
async function facilityDelete(facilityName) {
    return defaultFetch(Constants.CORE_API_BASE_URL + '/facility/' + facilityName, 'DELETE', { });
}
async function facilityEnable(facilityName) {
    return defaultFetch(Constants.CORE_API_BASE_URL + '/facility/' + facilityName, 'POST', { });
}

async function kpiRead(year, typeId) {
    const query = [];

    if (year !== undefined ) {
        query.push('year=' + year);    
    }

    if (typeId !== undefined) {
        query.push('typeId=' + typeId);
    }

    return defaultFetch(Constants.CORE_API_BASE_URL + `/kpi?${query.join('&')}`, 'GET', { });
}
async function kpiUpdate(form) {
    const body = { kpis: form }
    return defaultFetch(Constants.CORE_API_BASE_URL + '/kpi', 'POST', { body });
}

async function analyticsFilter(filterObj) {
    const query = toQueryString(filterObj);

    return defaultFetch(Constants.CORE_API_BASE_URL + `/analytics/filter${query}`, 'GET', { });
}
async function analyticsMeans(filterObj) {
    const query = toQueryString(filterObj);

    return defaultFetch(Constants.CORE_API_BASE_URL + `/analytics/means${query}`, 'GET', { });
}
async function analyticsModes(filterObj) {
    const query = toQueryString(filterObj);

    return defaultFetch(Constants.CORE_API_BASE_URL + `/analytics/modes${query}`, 'GET', { });
}
async function analyticsCategoryHistory() {
    return defaultFetch(Constants.CORE_API_BASE_URL + `/analytics/categoryHistory`, 'GET', { });
}
async function analyticsWorldData(year, kpiId) {
    const query = [];

    if (year !== undefined ) {
        query.push('year=' + year);    
    }

    if (kpiId !== undefined) {
        query.push('kpiId=' + kpiId);
    }

    return defaultFetch(Constants.CORE_API_BASE_URL + `/analytics/worldMap?${query.join('&')}`, 'GET', { });
}
async function analticsTreemapData(year) {
    const query = [];

    if (year !== undefined ) {
        query.push('year=' + year);    
    }

    return defaultFetch(Constants.CORE_API_BASE_URL + `/analytics/treeMap?${query.join('&')}`, 'GET', { });
}
async function analyticsTotalParticipants() {
    return defaultFetch(Constants.CORE_API_BASE_URL + '/analytics/totalParticipants', 'GET', { });
}
async function analyticsFilledForms(year) {
    const query = toQueryString({ year });

    return defaultFetch(Constants.CORE_API_BASE_URL + `/analytics/filledForms${query}`, 'GET', { });
}

async function getUserSettings() {
    return cloudPipeline.pipe(Constants.CLOUD_API_BASE_URL + `/settings`, 'GET', { responsify: false });
}

async function otherAuth(tenantId) {
    return defaultFetch(Constants.CORE_API_BASE_URL + '/otherAuth', 'POST', { body: { tenantId } });
}
async function companyList() {
    return defaultFetch(Constants.CORE_API_BASE_URL + '/companyList', 'GET', { });
}

async function refreshToken(accessToken, refreshToken) {
    return cloudPipeline.pipe(Constants.CLOUD_API_BASE_URL + '/auth/refreshtoken', 'POST', {
        body: { accessToken, refreshToken }, responsify: false
    });
}
async function logout() {
    return cloudPipeline.pipe(Constants.CLOUD_API_BASE_URL + `/auth/logout`, 'POST', { responsify: false });
}

function queryMap() {
    //Parse query params directlry from url
    const search = location.search;
    const paramMap = {};
    if (!search)
        return paramMap;

    const params = search.slice(1).split('&');
    params.forEach(p => {
        const splt = p.split('=');
        paramMap[splt[0]] = splt[1];
    });

    return paramMap;
}

// -------------- DEFAULT EXPORT ---------------
const API = {
    get errorCodes() {
        return API_ERROR_CODES;
    },

    get auth() {
        return {
            login,
            refreshToken: refreshToken
        };
    },

    get users() {
        return {
            settings: getUserSettings
        };
    },

    get admin() {
        return {
            companyList,
            otherAuth
        };
    },

    get create() {
        return {
            facility: facilityCreate
        };
    },
    get read() {
        return {
            company: companyRead,
            facility: facilityRead,
            kpi: {
                zootech: function(year) { return kpiRead(year, Constants.KPI_ZOOTECH_ID) },
                health: function(year) { return kpiRead(year, Constants.KPI_HEALTH_ID) },
                welfare: function(year) { return kpiRead(year, Constants.KPI_WELFARE_ID) },
                environment: function(year) { return kpiRead(year, Constants.KPI_ENVIRONMENT_ID) },
                economics: function(year) { return kpiRead(year, Constants.KPI_ECONOMICS_ID) },
                governance: function(year) { return kpiRead(year, Constants.KPI_GOVERNANCE_ID) }
            }
        };
    },
    get update() {
        return {
            facility: facilityUpdate,
            kpi: kpiUpdate
        };
    },
    get delete() {
        return {
            facility: facilityDelete
        };
    },
    get enable() {
        return {
            facility: facilityEnable
        }
    },

    get analytics() {
        return {
            filter: analyticsFilter,
            mean: analyticsMeans,
            mode: analyticsModes,
            categoryHistory: analyticsCategoryHistory,
            worldData: analyticsWorldData,
            treemapData: analticsTreemapData,
            totalParticipants: analyticsTotalParticipants,
            filledForms: analyticsFilledForms
        }
    },

    get url() {
        return {
            get queryMap() {
                return queryMap();
            } 
        };
    },

    get token() {
        return localStorage.getItem('token');
    },
    set token(token) {
        if (token === null) {
            localStorage.removeItem('token');
        } else {
            localStorage.setItem('token', token);
        }
    },

    get refreshToken() {
        return localStorage.getItem('refreshToken');
    },
    set refreshToken(token) {
        if (token === null) {
            localStorage.removeItem('refreshToken');
        } else {
            localStorage.setItem('refreshToken', token);
        }
    },

    logOut(reason='Session has expyred.') {
        logout(this.token).finally(() => {
            this.token = null;
            if (reason) {
                location.href = `${Constants.CURRENT_URL}?logoutReason=${reason}`;
            } else {
                location.pathname = '/';
            }
        });
    }
}

export default API;