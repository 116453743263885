<template>
    <div class="position-fixed bg-secondary screen-mask" />
    <div class="screen-mask-content card">
        <div class="card-body">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.card-body {
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
