<template>
    <div class="footer">
        <p class="my-auto">
            2020 © SmartWater Planet.
        </p>
    </div>
</template>

<script>
export default {
    name: 'AppFooter'
}
</script>