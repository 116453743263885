/* SubscriptionGroups enum */
const SubscriptionGroups = {
    CLOUD: 1,
    BENCHMARKING: 2,

    1: "CLOUD",
    2: "BENCHMARKING"
};

/* SubscriptionTypes enum */
const SubscriptionTypes = {
    BASIC: 1,
    STANDARD: 2,
    PREMIUM: 3,

    1: "BASIC",
    2: "STANDARD",
    3: "PREMIUM"
};

const SubscriptionManager = {

    _subscriptionGrouped: null,

    fromToken(sbs_t) {
        SubscriptionManager._subscriptionGrouped = {};

        Object.entries(sbs_t).forEach(([group, type]) => {
            const key = SubscriptionGroups[group];
            const value = type;
            SubscriptionManager._subscriptionGrouped[key] = value;
        });
    },

    /**
     * Check if user has a subscription from group
     * @param {[SubscriptionGroups, SubscriptionTypes][]} subGroups 
     * @returns {boolean} - true for any match
     */
    checkSubscription(subGroups) {
        if (SubscriptionManager._subscriptionGrouped == null) {
            throw 'not initialized';
        }

        for (let i = 0; i < subGroups.length; i++) {
            const group = subGroups[i];

            if (SubscriptionManager._subscriptionGrouped[group[0]] != undefined
                && SubscriptionManager._subscriptionGrouped[group[0]] == group[1]) {

                return true;
            }
        }

        return false;
    }
}


export { SubscriptionManager, SubscriptionGroups, SubscriptionTypes };