<template>
    <div
        id="usercomp-root"
        class="mobile-expandable ml-auto mr-4 my-0"
    >
        <a
            class="nav-link text-light d-flex"
            href="#"
            @mouseenter="buffer.hoverLink = true"
            @mouseleave="buffer.hoverLink = false"
        >
            <img
                ref="profileImage"
                class="profile"
                src="@/assets/avatar.jpg"
            >
            <span class="mx-2 my-auto"> {{ fullName }} </span>
            <i
                id="dropdown-user-menu-icon"
                class="fas fa-chevron-down my-auto"
            />
        </a>
        <div
            v-if="buffer.show"
            class="dropdown card bg-white"
            @mouseenter="buffer.hoverDrop = true"
            @mouseleave="buffer.hoverDrop = false"
        >
            <div class="myaccount">
                <div class="myaccount-email">
                    <p class="h5">
                        {{ fullName }}
                    </p>
                    <span>
                        {{ email }}
                    </span>
                </div>
                <div class="myaccount-btn">
                    <a
                        class="btn bg-dark text-white"
                        @click="myAccount"
                    >
                        {{ buffer.myAccountButton }}
                    </a>
                </div>
            </div>
            <a
                class="btn-wide mt-3 bg-secondary text-white"
                @click="logOut"
            >Sign out</a>
        </div>
    </div>
</template>

<script>
import Constants from '../Constants.js';
import API from '../utils/api-utils.js';

export default {
    name: 'UserAdminMenu',

    data() {
        return {
            buffer: {
                myAccountButton: 'My account',

                show: false,
                hoverDrop: false,
                hoverLink: false,
                token: API.token
            },

            fullName: '',
            email: ''
        }
    },

    watch: {
        'buffer.show'(newVal) {
            if (newVal) {
                document.getElementById('dropdown-user-menu-icon').style.transform = 'rotate(180deg)';
            } else {
                document.getElementById('dropdown-user-menu-icon').style.transform = 'rotate(0deg)';
            }
        }
    },

    created() {    
        API.users.settings()
            .then(response => {
                if (response.ok) {

                    this.fullName = `${response.data.firstName} ${response.data.lastName}`;
                    this.email = response.data.email;

                }
            })
            .catch(() => {});

        window.addEventListener('click', () => {
            if (this.buffer.hoverLink) {
                this.buffer.show = !this.buffer.show;
            } else if (!this.buffer.hoverDrop) {
                this.buffer.show = false;
            }
        });

        window.addEventListener('keydown', (ev) => {
            if (ev.key == 'Escape' && this.buffer.show)
                this.buffer.show = false;
        });
    },

    methods: {
        logOut(ev) {
            ev.preventDefault();
            API.logOut('');
        },

        myAccount(ev) {
            ev.preventDefault();
            const url = `${Constants.CLOUD_URL}/settings/4?accessToken=${API.token}&refreshToken=${API.refreshToken}`;
            window.open(url, '_blank');
        }
    }
}
</script>

<style lang="scss">
@import '../_custom.scss';

@include media(xs) {
    #usercomp-root {
        width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;

        .nav-link {
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
        }

        .dropdown {
            position: relative !important;
            right: 0px;
            width: unset;
        }

        .myaccount {
            display: block !important;

            .myaccount-email {
                width: 100% !important;
                text-align: center;
            }

            .myaccount-btn {
                margin-top: 20px;
                width: 100% !important;

                >a {
                    width: 100%;
                }
            }

        }

    }
};

#dropdown-user-menu-icon {
    transition: transform 0.15s ease;
}

.dropdown {
    width: 450px;
    right: 10px;
    padding: 20px;
    position: absolute;
}

#usercomp-root {
    z-index: 9;

    .myaccount {
        display: flex;
    }

    .myaccount-email {
        width: 65%;
    }

    .myaccount-btn {
        width: 35%;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
    }
}

img.profile {
    height: 30px;
    border-radius: 100px;
}
</style>