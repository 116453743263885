<template>
    <div class="notification-area">
        <slot name="notifications" />
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.notification-area {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    width: 60%;
    min-width: 400px;
}
</style>