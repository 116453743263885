<template>
    <div class="xs-hide navbar navbar-expand-lg bg-white shadow-bottom px-5">
        <a
            v-for="item in menuItems"
            :key="item.title"
            :class="`${item.active ? 'active' : ''} nav-link`"
            href=""
            @click="item.onclick"
        ><i :class="item.iconClass" /> {{ item.title }}</a>
    </div>
    <div class="xs-show navbar card bg-white mobile">
        <p
            class="h2 text-center my-2"
            @click="collapse"
        >
            <i class="fas fa-bars" />
        </p>
        <div
            ref="content"
            class="content"
            style="max-height: 0px"
        >
            <ul class="list-group">
                <li 
                    v-for="item in menuItems"
                    :key="item.title"
                    class="list-group-item text-center"
                >
                    <a
                        :class="`${item.active ? 'active' : ''} nav-link`"
                        href=""
                        @click="item.onclick"
                    ><i :class="item.iconClass" /> {{ item.title }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    /*
    [
        {
            title: '',
            onclick: () => {},
            iconClass: '',
            active: bool
        }
    ]
    */
    props: {
        menuItems: {
            type: Array(Object),
            required: true,
        },
    },

    data() {
        return {
            isCollapsed: true
        }
    },

    created() {
        window.addEventListener('resize', this.resize);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.resize);
    },

    updated() {
        this.resize();
    },

    methods: {
        resize() {
            const elem = this.$refs.content;

            //Update the content max-height for dynamic DOM changes
            if (!this.isCollapsed)
                elem.style.maxHeight = elem.scrollHeight + 'px';
        },

        collapse(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            const elem = this.$refs.content
            elem.style.maxHeight = this.isCollapsed ? elem.scrollHeight + 'px' : '0px';

            this.isCollapsed = !this.isCollapsed;
        }
    }
};
</script>

<style lang="scss" scoped>
.content {
    overflow: hidden;
    transition: max-height ease-in-out .3s;
}
.nav-link {
    color: #515463 !important;

    &.active {
        color: #15BDC7 !important;
    }

    &:focus {
        color: #15BDC7 !important;
    }

    &:hover {
        color: #15BDC7 !important;
    }
}
</style>
